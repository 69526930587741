/** @jsx jsx */
import { selectorGlobalConfig } from "gatsby-plugin-hfn-profile/state/selectors";
import { ActionButton, Stack } from "office-ui-fabric-react";
import { useSelector } from "react-redux";
import { jsx } from "theme-ui";
import { useEffect, useState } from "react";
import ProfileWidgetCore from "../../components/ProfileWidgetCore";
import DLWLayout from "../../layouts/dlw-mobile";
import { generateCodeChallenge, getRedirectUrl } from "../../lib/oneauth";

const getMandatoryEnv = require("sites-common/utils/getMandatoryEnv");

const { keycloakConfig } = getMandatoryEnv(["keycloakConfig"]);

function ProfilePage() {
  const { tosUrl, privacyPolicyUrl } = useSelector(selectorGlobalConfig);
  const [code_challenge, setCodeChallenge] = useState("");
  const getCodeChallenge = async () =>
    setCodeChallenge(await generateCodeChallenge());
  useEffect(() => {
    getCodeChallenge();
  }, []);

  return (
    <DLWLayout>
      <ProfileWidgetCore />
      <hr sx={{ mt: 4, mb: 1 }} />
      <Stack horizontal horizontalAlign="space-evenly">
        <ActionButton href="/" text="My Home" />
        <ActionButton
          href={`${keycloakConfig?.authUrl}/realms/${
            keycloakConfig?.realm
          }/protocol/openid-connect/auth?client_id=${
            keycloakConfig?.client_id
          }&redirect_uri=${getRedirectUrl()}&response_type=code&scope=openid&kc_action=UPDATE_PASSWORD&code_challenge=${code_challenge}&code_challenge_method=S256`}
          text="Change Password"
          target="_blank"
        />
        <ActionButton
          target="_blank"
          href={tosUrl?.in}
          text="Terms of Service"
        />
        <ActionButton
          target="_blank"
          href={privacyPolicyUrl?.in}
          text="Privacy Policy"
        />
      </Stack>
    </DLWLayout>
  );
}

export default ProfilePage;
